import { MOUNT_POINT_CLASSNAME } from "./definitions";
export function checkVerificationStatusByLocalStorage(checkId) {
    return (window.localStorage.getItem(`real-id-check-${checkId}-completed`) === "true");
}
export function setCheckVerificationStatusInLocalStorage(checkId, state = true) {
    return window.localStorage.setItem(`real-id-check-${checkId}-completed`, state.toString());
}
export function setCheckIdInLocalStorage(checkId) {
    window.localStorage.setItem("real-id-check-id", checkId);
}
export function getCheckIdFromLocalStorage() {
    return window.localStorage.getItem("real-id-check-id");
}
export function getCheckIdFromQueryParams() {
    const currentQueryParams = new URLSearchParams(window.location.search);
    return currentQueryParams.get("checkId");
}
export function hideVerifyIdButton() {
    const checkBtnEls = document.getElementsByClassName(MOUNT_POINT_CLASSNAME);
    Array.prototype.forEach.call(checkBtnEls, (el) => {
        el.style.display = "none";
    });
}
export function enableCheckoutButtons(entrySelectors) {
    const nodes = document.querySelectorAll(entrySelectors.join(", "));
    if (!nodes) {
        return null;
    }
    [...nodes].map((el) => {
        el.disabled = false;
    });
}
