import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { httpClientIntegration } from "@sentry/integrations";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./locale/i18n";
import { detectStrategy, loadPlatformBootstrapProps, } from "./mounting/definitions";
import detectCurrentPlatform from "./mounting/detectCurrentPlatform";
import console from "./log";
Sentry.init({
    dsn: "https://78f7f6c935294bf790e4228d78c35ba3@o249565.ingest.sentry.io/6041382",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        httpClientIntegration({
            failedRequestTargets: [
                "https://real-id.getverdict.com",
                "https://idv.link",
            ],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.05,
    // Capture Replay for 10% of all sessions,
    // plus for 25% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.25,
    allowUrls: ["gitpod.io", "ngrok.io", "getverdict.com", "idv.link"],
});
async function setupEnvironment() {
    const platform = detectCurrentPlatform();
    console.debug(`Real ID :: detected platform :: ${platform}`);
    console.debug(`Real ID :: environment :: ${process.env.NODE_ENV}`);
    const platformBootstrapProps = await loadPlatformBootstrapProps(platform);
    console.debug(platformBootstrapProps);
    const strategy = detectStrategy(platform, platformBootstrapProps, initialize);
    if (!strategy) {
        new Error("Real ID :: No matching strategy found");
    }
    const { entrySelectors, onIdCheckError, onIdCheckSuccess, onIdCheckFail, onIdCheckCreate, bootstrap, } = strategy;
    const props = await bootstrap();
    const mountEls = strategy.createMount({ entrySelectors, props, initialize });
    const additionalProps = await strategy.getAdditionalEnvironmentProps();
    const environment = {
        strategy: strategy.strategy,
        platform,
        onIdCheckError,
        onIdCheckSuccess,
        onIdCheckFail,
        onIdCheckCreate,
        // used only for automated ID checks with Vouched
        //  if the ID check is manual, then the result is always "in_review" because the merchant needs to manually review it
        onIdCheckComplete: ({ env, precheckoutDone, setCheck, check, staleCheck, done, }) => {
            const newCheck = {
                ...staleCheck,
                ...{ step: check?.step },
                ...{ job: check?.job },
            };
            setCheck(newCheck);
            console.debug(`Real ID :: onIdCheckComplete`);
            // user has failed their ID check
            if (check?.step === "in_review" || !check?.job?.result?.success) {
                env.onIdCheckError?.("results_in_review");
                const failedCheckEvent = new CustomEvent("real-id-check-failed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-failed event");
                    window?.dispatchEvent(failedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-check-failed event");
                    console.error(e);
                }
                done();
                return;
            }
            // user has successfully completed an ID check
            if (check?.step === "completed" && check?.job?.result?.success) {
                console.debug(`Real ID :: check ${check?.id} :: completed successfully`);
                env.onIdCheckSuccess(check);
                if (precheckoutDone?.setDone) {
                    precheckoutDone.setDone(true);
                }
                const passedCheckEvent = new CustomEvent("real-id-check-passed", {
                    detail: {
                        check: newCheck,
                    },
                });
                try {
                    console.debug("Real ID :: emitting real-id-check-passed event");
                    window?.dispatchEvent(passedCheckEvent);
                }
                catch (e) {
                    console.debug("Real ID :: failed to emit real-id-passed-check event");
                    console.error(e);
                }
                done();
            }
            // either way, pass, fail or in_review - move to the "done" step to show the final screen
            console.debug(`Real ID :: onCheckComplete :: updating check to`, {
                ...staleCheck,
                ...{ job: check?.job },
            });
        },
        ...additionalProps,
        mountEls,
    };
    console.debug(`Real ID :: initialized environment`);
    console.debug(environment);
    return { environment, mountEls, strategy };
}
function ErrorFallback() {
    const { t } = useTranslation();
    return (_jsx("div", { role: "alert", children: _jsx("p", { children: t("components.App.error_fallback") }) }));
}
const fallback = _jsx(ErrorFallback, {});
function initialize() {
    if (!window.realIdManager) {
        window.realIdManager = { roots: [] };
    }
    setupEnvironment()
        .then(({ environment, mountEls, strategy, }) => {
        console.debug(`Real ID :: initialized environment`);
        console.table(environment);
        Sentry.setTag("platform", environment?.platform);
        Sentry.setTag("strategy", environment?.strategy);
        if (mountEls) {
            const roots = mountEls.map((mountEl) => {
                console.debug(`Mounting Real ID to `, mountEl);
                const AppComponent = strategy.rootComponent();
                const root = createRoot(mountEl);
                root.render(_jsx(Suspense, { fallback: _jsx("div", {}), children: _jsx(Sentry.ErrorBoundary, { fallback: fallback, showDialog: true, children: _jsx(AppComponent, { environment: environment }) }) }));
                return root;
            });
            // apply the real-id-mounted=true attribute to all entry selectors for the strategy
            // we're doing this after the mounts have been created, so the "real-id-mounted" state is as true as possible
            const entryEls = document.querySelectorAll(strategy.entrySelectors.join(", "));
            [...entryEls].map((el) => {
                el.setAttribute("real-id-mounted", "true");
            });
            window.realIdManager.roots = roots;
        }
    })
        .catch((e) => console.debug(e));
}
initialize();
window.addEventListener("real-id-initialize", () => {
    console.log("real-id-intialize received");
    initialize();
});
const eventEmitEls = document.querySelectorAll('.real-id-initialize, .js-go-cart-trigger, .js-go-cart-add-to-cart, [data-btn-addToCart], [data-btn-addtocart], [data-cart-sidebar], [data-add-to-cart-text], [data-action="increment"], .shopify-installments, [data-open-quick-view-popup], .quickview-icon, .modem-main');
if (eventEmitEls && eventEmitEls.length > 0) {
    eventEmitEls.forEach((eventEmitEl) => {
        console.debug(`Real ID :: registering initialize event emitter`);
        eventEmitEl.addEventListener("click", () => {
            console.debug("Real ID :: emit real-id-intialize event");
            const btnClick = new CustomEvent("real-id-initialize");
            window.dispatchEvent(btnClick);
        });
    });
}
/**
 * WooCommerce Checkout Blocks integration
 *
 * WooCommerce will emit @wordpress/hook events on specific checkout events, like when the form is loaded.
 * If the normal initization beats the client side render, then listen for the specific checkout render finished event
 *
 * List of WC events:
 * https://github.com/woocommerce/woocommerce/blob/trunk/plugins/woocommerce-blocks/docs/internal-developers/blocks/feature-flags-and-experimental-interfaces.md#usages-of-experimental-prefix
 *
 * @wordpress/hooks docs:
 * https://developer.wordpress.org/block-editor/reference-guides/packages/packages-hooks/
 */
if (window?.wp?.hooks) {
    console.debug("WC :: block usage detected");
    const hooks = window.wp.hooks;
    hooks.addAction("experimental__woocommerce_blocks-checkout-render-checkout-form", "realidcheckoutexperimental", () => {
        console.debug("WC :: checkout form rendered");
        // re-initialize flow, now that the form is rendered
        setTimeout(() => {
            initialize();
        }, 100);
    });
    hooks.addAction("woocommerce_blocks-checkout-render-checkout-form", "realidcheckout", () => {
        console.debug("WC :: checkout form rendered");
        // re-initialize flow, now that the form is rendered
        setTimeout(() => {
            initialize();
        }, 100);
    });
}
if (window?.jQuery) {
    console.log("looking for ninjaforms");
    window.jQuery(document).on("nfFormReady", () => {
        console.log("Ninja forms loaded");
        initialize();
    });
}
