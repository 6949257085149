import { MOUNT_POINT_CLASSNAME } from "./definitions";
import axios from "axios";
import { getWcRestApiRoot } from "../helpers";
export function checkVerificationStatusByLocalStorage(checkId) {
    return (window.localStorage.getItem(`real-id-check-${checkId}-completed`) === "true");
}
export function setCheckVerificationStatusInLocalStorage(checkId, state = true) {
    return window.localStorage.setItem(`real-id-check-${checkId}-completed`, state.toString());
}
export function setCheckIdInLocalStorage(checkId) {
    window.localStorage.setItem("real-id-check-id", checkId);
}
export function getCheckIdFromLocalStorage() {
    return window.localStorage.getItem("real-id-check-id");
}
export function getCheckIdFromQueryParams() {
    const currentQueryParams = new URLSearchParams(window.location.search);
    return currentQueryParams.get("checkId");
}
export function hideVerifyIdButton() {
    const checkBtnEls = document.getElementsByClassName(MOUNT_POINT_CLASSNAME);
    Array.prototype.forEach.call(checkBtnEls, (el) => {
        el.style.display = "none";
    });
}
export function enableCheckoutButtons(entrySelectors) {
    const nodes = document.querySelectorAll(entrySelectors.join(", "));
    if (!nodes) {
        return null;
    }
    [...nodes].map((el) => {
        el.disabled = false;
    });
}
export function setCheckIdInWcSession(checkId) {
    axios
        .post(getWcRestApiRoot() + "real-id/v1/public/session", {
        check_id: checkId,
    }, {
        headers: {
            ...(window?.realIdWpNonce
                ? { "X-WP-Nonce": window?.realIdWpNonce }
                : {}),
        },
    })
        .then(() => console.log("wc session updated"))
        .catch((e) => console.error(e));
}
