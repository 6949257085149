import axios from "./utilities/axios";
import * as Sentry from "@sentry/react";
import console from "./log";
export const getShopifyShopName = () => {
    if (window.Shopify) {
        return window.Shopify.shop;
    }
    // Shopify appends ?shop=<shopname> to every script tag as a query param.
    const currentScriptUrl = document.currentScript?.src;
    if (!currentScriptUrl) {
        null;
    }
    const url = new URL(currentScriptUrl);
    const shopNameByScriptUrl = new URLSearchParams(url?.search).get("shop");
    console.log("shopName", window.Shopify.shop || shopNameByScriptUrl);
    return shopNameByScriptUrl;
};
export function getShopifyCustomerId() {
    if (window?.__st?.cid) {
        return window?.__st?.cid;
    }
    if (window?.Shopify?.checkout?.customer_id) {
        return window.Shopify.checkout.customer_id;
    }
    if (window?.ShopifyAnalytics?.meta?.page?.customerId) {
        return window?.ShopifyAnalytics?.meta?.page?.customerId;
    }
    return false;
}
export function getShopifyOrderId() {
    return window?.Shopify?.checkout?.order_id;
}
export const getWcShopName = () => {
    const shopName = window.realIdShopName;
    console.log(`Real ID :: realIDShopName :: ${shopName || new URL(window.location.href).origin}`);
    // returning early as an experiment, because home_url() should be accurate
    // The current URL's origin is a backstop, just in case the PHP JS exposed realId* vars are loaded _after_ the flow script tag
    return shopName || new URL(window.location.href).origin;
    // a popular translation plugin changes the home_url() of a wordpress site depending on the locale selected
    // it can be configured to use query params to store locale in the home_url()
    // using URL(shopName).origin will strip out any query params in the URL so we have a more accurate look up
    try {
        return new URL(shopName).origin;
    }
    catch (e) {
        console.log(`Real ID :: getWcShopName failed, returning vanilla shopname`);
        console.error(e);
        return shopName;
    }
};
export const getWcShopSettings = async () => {
    const cached = window.localStorage.getItem("real-id-shop-settings");
    // seems crazy, but WP permalink structure can fuck things up quick
    // https://wordpress.stackexchange.com/questions/273144/can-i-use-rest-api-on-plain-permalink-format
    const byDocument = document.querySelectorAll('link[rel="https://api.w.org/"]');
    const restHome = window?.realIdShopWpRestUrl ?? byDocument[0]?.getAttribute("href");
    console.debug(`Real ID :: shop wp rest route :: ${restHome}`);
    const wpRestSettingsPath = `${restHome}real-id/v1/shop/public/settings`;
    // just a back up in case the plugin isn't updated to v1.2.11 and the settings proxy route isn't available
    // const coreSettingsPath = `${
    //   process.env.VITE_REAL_ID_HOST
    // }/api/shop/${getWcShopName()}/settings`;
    const coreSettingsPath = `${process.env.VITE_REAL_ID_HOST}/api/shop/${encodeURIComponent(getWcShopName())}/settings`;
    if (cached) {
        // still update local settings to latest version, the cache shouldn't last forever
        axios
            .get(wpRestSettingsPath)
            .then(({ data }) => {
            window.localStorage.setItem("real-id-shop-settings", JSON.stringify(data.shopSettings));
        })
            .catch((e) => {
            console.error(e);
            axios.get(coreSettingsPath).then(({ data }) => {
                window.localStorage.setItem("real-id-shop-settings", JSON.stringify(data.shopSettings));
            });
        });
        return JSON.parse(cached);
    }
    try {
        const response = await axios.get(wpRestSettingsPath);
        window.localStorage.setItem("real-id-shop-settings", JSON.stringify(response.data.shopSettings));
        return response.data.shopSettings;
    }
    catch (error) {
        console.error(error);
        // as a backup, try the WP REST proxied settings path instead.
        axios.get(coreSettingsPath).then(({ data }) => {
            window.localStorage.setItem("real-id-shop-settings", JSON.stringify(data.shopSettings));
        });
        Sentry.captureException(error);
        return {};
    }
};
export const getCrossDeviceToken = () => {
    return window.localStorage.getItem("real-id-cross-device-token");
};
export function setLocalStorageCheckId(checkId) {
    return window.localStorage.setItem("real-id-check-id", checkId);
}
export function getLocalStorageCheckId() {
    return window.localStorage.getItem("real-id-check-id");
}
export function removeLocalStorageCheckId() {
    return window.localStorage.removeItem("real-id-check-id");
}
export function getCheckIdCompletedFlag(checkId) {
    return window.localStorage.getItem(`real-id-check-${checkId}-completed`);
}
export async function getShopSettings(shopName) {
    const cached = window.localStorage.getItem("real-id-shop-settings");
    if (cached) {
        // still update local settings to latest version, the cache shouldn't last forever
        axios
            .get(`${process.env.VITE_REAL_ID_HOST}/api/shop/${shopName}/settings`)
            .then(({ data }) => {
            window.localStorage.setItem("real-id-shop-settings", JSON.stringify(data.shopSettings));
        })
            .catch((e) => {
            console.error(e);
        });
        return JSON.parse(cached);
    }
    try {
        const response = await axios.get(`${process.env.VITE_REAL_ID_HOST}/api/shop/${shopName}/settings`);
        window.localStorage.setItem("real-id-shop-settings", JSON.stringify(response.data.shopSettings));
        return response.data.shopSettings;
    }
    catch (error) {
        console.error(error);
        Sentry.captureException(error);
        return {};
    }
}
export async function getRegistrationSettings(shopName) {
    // attempt to grab the settings from the localStorage cache first
    const serializedRegistrationSettings = window?.localStorage.getItem("real-id-registration-settings");
    if (serializedRegistrationSettings) {
        return JSON.parse(serializedRegistrationSettings);
    }
    // if we get here, registration settings were not found
    const response = await axios.get(`${process.env.VITE_REAL_ID_HOST}/api/shop/${shopName}/registration-settings`);
    const registrationSettings = response.data;
    // TODO: move this to a post-bootstrap or some other background process that's not interrupting the main flow
    window?.localStorage.setItem("real-id-registration-settings", JSON.stringify(registrationSettings));
    return registrationSettings;
}
export async function getCheckByCustomer(shopName, customerId) {
    if (!customerId || !shopName) {
        return null;
    }
    try {
        const response = await axios.get(`${process.env.VITE_REAL_ID_HOST}/api/shop/${shopName}/customer/${customerId}/check`);
        const check = response.data?.check;
        return check;
    }
    catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}
export async function createWcCustomerCheck() {
    const shopName = getWcShopName();
    const customer = getWcCurrentUser();
    if (!customer?.id || !shopName) {
        return null;
    }
    try {
        const response = await axios.post(`${process.env.VITE_REAL_ID_HOST}/api/customers/wc/create-check`, {
            customer,
            shopName,
        });
        const check = response.data?.check;
        return check;
    }
    catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}
export function getVouchedAllowedCaptureMethod(allowedCaptureMethods) {
    if (allowedCaptureMethods.includes("camera") &&
        allowedCaptureMethods.includes("upload")) {
        return "both";
    }
    if (allowedCaptureMethods.length === 0) {
        return "both";
    }
    return allowedCaptureMethods[0];
}
export function getWcCustomerId() {
    return window?.realIdCustomerId;
}
export function getWcCurrentUser() {
    return window?.realIdCurrentUser;
}
export function renderContent(content = "", params = {}) {
    let renderedContent = content
        .replace(/\[firstName\]/g, params.firstName || "")
        .replace(/\[lastName\]/g, params.lastName || "");
    // not doing this leaves a weird 'undefined' string
    if (params.orderId) {
        renderedContent = renderedContent.replace(/\[orderId\]/g, params.orderId);
    }
    else {
        renderedContent = renderedContent.replace(/\[orderId\]/g, "");
    }
    return renderedContent.replace("undefined", "");
}
export function renderCustomReturnUrl({ url, params, }) {
    let returnUrl = url
        .replace(/{{orderId}}/g, params?.orderLegacyId ||
        getLegacyIdFromGraphId(params.orderGraphId || "") ||
        "")
        // looks like it's incorrect but this is true. The order numer is stored as orderId in the database
        // also removing the hash if present (nomadplus.myshopify.com request)
        .replace(/{{orderName}}/g, (params?.orderId || "").replace(/#/g, ""));
    return returnUrl;
}
/**
 * Find the legacy ID from a graph ID
 * @param graphId
 * @returns
 */
export function getLegacyIdFromGraphId(graphId) {
    const legacyId = graphId.match(/\d+$/);
    if (legacyId && legacyId.length > 0) {
        // Output the extracted trailing number
        return legacyId[0];
    }
    else {
        console.log("No legacy ID found.");
        return null;
    }
}
