/**
 * Detect the current platform that the current script is being hosted by
 *
 * @returns "wc" || "shopify" || "hosted"
 */
export default function detectCurrentPlatform() {
    if ((window && window.woocommerce_params) ||
        window.realIdShopName) {
        return "wc";
    }
    if (window && window.Shopify) {
        return "shopify";
    }
    // no signs of WooCommerce or Shopify? Must be on our infrastructure
    return "hosted";
}
